window.language_HR = {
	"lengthMenu": "Prikaži _MENU_ zapisa po stranici",
	"zeroRecords": "Nema podataka",
	"info": "Prikazano _START_ do _END_ od _TOTAL_ rezultata",
	//"info": "Stranica _PAGE_ od _PAGES_",
	"infoEmpty": "Nije dohvaćen niti jedan zapis",
	"infoFiltered": "(filtrirano iz skupa od ukupno _MAX_ zapisa)",
	"sSearch": "Pretraži:",
	"oPaginate": {
	    "sFirst":    "Prva",
	    "sLast":    "Zadnja",
	    "sNext":    "<i class='fas fa-chevron-right'></i>",
	    "sPrevious": "<i class='fas fa-chevron-left'></i>"
	},
    };

function arr_diff(a1, a2){
    var a = [], diff = [];
    for (var i = 0; i < a1.length; i++) {
	a[a1[i]] = true;
    }
    for (var i = 0; i < a2.length; i++) {
	if (a[a2[i]]) {
	    delete a[a2[i]];
	} else {
	    a[a2[i]] = true;
	}
    }
    for (var k in a) {
	diff.push(k);
    }
    return diff;
}

function enable_select2(element_id, maximum_selected){
    let branch_all3 = [];
    
    let optionSelect3 = {
        closeOnSelect: false,
        width: '100%'
    };
    
    if(maximum_selected !== undefined){
	optionSelect3['maximumSelectionLength'] = maximum_selected;
    }
    
    
    let $select3 = $('#' + element_id).select2(optionSelect3);
    
    var scrollTop;
    
    $select3.on("select2:selecting", function( event ){
        var $pr = $( '#'+event.params.args.data._resultId ).parent();
        scrollTop = $pr.prop('scrollTop');
    });
    
    $select3.on("select2:select", function( event ){
        $(window).scroll();
        
        var $pr = $( '#'+event.params.data._resultId ).parent();
        $pr.prop('scrollTop', scrollTop );
	
        $(this).val().map(function(index) {
            $("#state"+index).prop('checked', true);
        });
    });
    
    $select3.on("select2:unselecting", function ( event ) {
        var $pr = $( '#'+event.params.args.data._resultId ).parent();
        scrollTop = $pr.prop('scrollTop');
    });
    
    $select3.on("select2:unselect", function ( event ) {
        $(window).scroll();
        
        var $pr = $( '#'+event.params.data._resultId ).parent();
        $pr.prop('scrollTop', scrollTop );
        
        var branch	=	$(this).val() ? $(this).val() : [];
        var branch_diff = arr_diff(branch_all3, branch);
        branch_diff.map(function(index) {
            $("#state"+index).prop('checked', false);
        });
    });
}

module.exports = {
    enable_select2,
};

$(document).on('click', '.role-changer', function(){
    
    if(confirm('Da li ste sigurni da želite promijeniti Vaš status?')){
    
        var $formData = new FormData();

        $formData.append('type', $(this).data('type'));

        $.ajax({
            type: "POST",
            url: route_sendUserStatus,
            data: $formData,
            contentType: false,
            processData: false,
            error: function (jqXHR, exception) {
            },
        }).done(function (data) {
            if(data.status == true){
                location.reload();
            }
        });
    }
    
    return false;
});
