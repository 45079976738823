/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

/*
// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';

// start the Stimulus application
import './bootstrap';
*/







/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('./styles/app.scss');


// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
//const $ = require('jquery');
const $ = require('jquery');

require('jquery-ui-bundle');

// create global $ and jQuery variables
//global.$ = global.jQuery = $;

global.$ = global.jQuery = $;

var bootstrap = require('bootstrap');
global.bootstrap = bootstrap;

require( 'datatables.net-bs4' )( window, $ );

//require('datatables.net-bs4');
require('datatables.net-bs4/css/dataTables.bootstrap4.css');
//require('datatables.net-buttons-bs4');
require('datatables.net-buttons-bs4')( window, $ );
require('datatables.net-buttons/js/dataTables.buttons.js')( window, $ );
require('datatables.net-buttons/js/buttons.html5.js')( window, $ );

require('select2')( window, $ );
require('select2/dist/css/select2.css');

var JSZip = require('jszip');
global.JSZip = JSZip;

import './js/globals.js';

//import dt from 'datatables.net-bs4';
//var dt = require('datatables.net-bs4')(window, $);



require('@fortawesome/fontawesome-free/css/all.min.css');
//require('@fortawesome/fontawesome-free/js/all.js');






//console.log('Hello Webpack Encore! Edit me in assets/js/app.js');



(function($) {
  "use strict"; // Start of use strict

  // Toggle the side navigation
  $("#sidebarToggle").on('click', function(e) {
    e.preventDefault();
    $("body").toggleClass("sidebar-toggled");
    $(".sidebar").toggleClass("toggled");
  });

  // Prevent the content wrapper from scrolling when the fixed side navigation hovered over
  $('body.fixed-nav .sidebar').on('mousewheel DOMMouseScroll wheel', function(e) {
    if ($(window).width() > 768) {
      var e0 = e.originalEvent,
        delta = e0.wheelDelta || -e0.detail;
      this.scrollTop += (delta < 0 ? 1 : -1) * 30;
      e.preventDefault();
    }
  });

  // Scroll to top button appear
  $(document).on('scroll', function() {
    var scrollDistance = $(this).scrollTop();
    if (scrollDistance > 100) {
      $('.scroll-to-top').fadeIn();
    } else {
      $('.scroll-to-top').fadeOut();
    }
  });

  // Smooth scrolling using jQuery easing
  $(document).on('click', 'a.scroll-to-top', function(event) {
    var $anchor = $(this);
    $('html, body').stop().animate({
      scrollTop: ($($anchor.attr('href')).offset().top)
    }, 1000, 'easeInOutExpo');
    event.preventDefault();
  });

})($); // End of use strict

